import React from 'react';
import {Link} from 'gatsby';

const CategoriesList = ({ categories, path}) => {

  return (
    <div className="tags-container article_card-outer">
      <div>Categories:</div>
      {categories.map(({ id, title, slug }) => (
        <Link key={id} to={`/articles/categories/${slug}`}
          className={path === `/articles/categories/${slug}` ? "tags tag-selected" : 'tags'}  >
          {title}
        </Link>
      ))}
    </div>
  );
}

export default CategoriesList;
