import React from 'react';
import {graphql} from 'gatsby';
import {motion} from 'framer-motion';
import {PageHeader} from '../components/Headings';
import Layout from '../components/Layout';
import {pageVariants, pageTransition} from '../components/Transitions';
import BlogCard from '../components/BlogCard';
import CategoriesList from '../components/CategoriesList';

const Articles = ({data, ...rest}) => {
  const {
    allCraftBlogArticlesBlogArticleEntry,
    craftAllblogsAllblogsEntry,
    allCraftBlogCategory: {categories},
  } = data;
  const {description, heading, seomatic} = craftAllblogsAllblogsEntry;
  const {edges} = allCraftBlogArticlesBlogArticleEntry;
  const { path } = rest;
  return (
    <Layout hasContact seomatic={seomatic}>
      <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition} className=" font-display">
        <div className="background relative">
          <PageHeader heading={heading} subheading={description} />
        </div>
        <div className="article-container">
          <CategoriesList categories={categories}  path={ path} />
          <div className="article">
            <div className="article_card-outer">
              {edges.map(blog => (
                <BlogCard key={blog.node.title} node={blog.node} />
              ))}
            </div>
          </div>
        </div>
      </motion.div>
    </Layout>
  );
};

export const query = graphql`
  query ArticlesQuery {
    craftAllblogsAllblogsEntry {
      description
      heading
      seomatic {
        ...SeomaticFields
      }
    }
    allCraftBlogCategory {
      categories: nodes {
        id
        title
        slug
      }
    }
    allCraftBlogArticlesBlogArticleEntry(filter: {enabled: {eq: true}, status: {eq: "live"}}) {
      edges {
        node {
          url
          title
          intro
          heroImage {
            ... on Craft_assets_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 375, height: 250, quality: 80, transformOptions: {cropFocus: CENTER})
                }
              }
            }
          }
          categories {
            title
            slug
          }
        }
      }
    }
  }
`;

export default Articles;
