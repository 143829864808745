import dayjs from 'dayjs';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

const BlogCard = ({node, filter}) => {
  const {url, title, intro, heroImage, categories} = node;

  if (filter && categories.every(category => category.title !== filter)) {
    return null;
  }

  return (
    <div className="article_card-inner">
      <a className="article_card" href={url}>
        <GatsbyImage
          image={getImage(heroImage?.[0]?.localFile)}
          alt={title}
          className="transition duration-300"
        />
        <div className="article_card-text">
          <div>
            <div className="article_card-title">{title}</div>
            <div className="article_card-description" dangerouslySetInnerHTML={{__html: intro}} />
          </div>
        </div>
      </a>
    </div>
  );
};

export default BlogCard;
